
import AddressPicker from '@/components/address_picker.vue'
import PageNavbar from '@/components/page_navbar'
import DeliveryAddressesEmpty from '@/components/address/delivery_addresses_empty.vue'

export default {
  components: { AddressPicker, PageNavbar, DeliveryAddressesEmpty }
  methods: {
    create_address: (new_address) ->
      @$store.dispatch('account/create_address', new_address)

    request_address: ->
      @$store.commit('address_picker/open')

    remove_address: (id) ->
      @$store.dispatch('account/remove_address', id)
  }
}
