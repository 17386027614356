<template>
  <div class='d-flex flex-column align-center r---address-empty-wrapper'>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="r---address-empty-icon">
      <path d="M35.314 33.314L26.828 41.8C26.4569 42.1715 26.0162 42.4662 25.5311 42.6673C25.0461 42.8683 24.5261 42.9718 24.001 42.9718C23.4759 42.9718 22.956 42.8683 22.4709 42.6673C21.9858 42.4662 21.5451 42.1715 21.174 41.8L12.686 33.314C10.4484 31.0763 8.92468 28.2254 8.30738 25.1217C7.69009 22.0181 8.00699 18.8011 9.21801 15.8775C10.429 12.9539 12.4798 10.4551 15.111 8.69704C17.7421 6.93897 20.8355 6.00061 24 6.00061C27.1645 6.00061 30.2579 6.93897 32.889 8.69704C35.5202 10.4551 37.571 12.9539 38.782 15.8775C39.993 18.8011 40.3099 22.0181 39.6926 25.1217C39.0753 28.2254 37.5516 31.0763 35.314 33.314V33.314Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M30 22C30 23.5913 29.3679 25.1174 28.2426 26.2426C27.1174 27.3679 25.5913 28 24 28C22.4087 28 20.8826 27.3679 19.7574 26.2426C18.6321 25.1174 18 23.5913 18 22C18 20.4087 18.6321 18.8826 19.7574 17.7574C20.8826 16.6321 22.4087 16 24 16C25.5913 16 27.1174 16.6321 28.2426 17.7574C29.3679 18.8826 30 20.4087 30 22V22Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="r---address-empty-header">{{ $t('no_delivery_addresses_header') }}</div>
    <div class="r---address-empty-body">{{ $t('no_delivery_addresses_body') }}</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>